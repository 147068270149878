import Vue from 'vue'
const fb = require('../firebaseConfig.js')

async function getData(doc) {
    return Object.assign({id: doc.id}, await doc.data())
}

export default {

    state: () => ({
        list: [],
        loading: false
    }),

    actions: {
        async init({commit, dispatch}) {
            commit('flushJournals')
            const journals = await fb.journalsCollection.get()
            for (let journal of journals.docs) {
                let result = await getData(journal)
                result.issues = []
                result.authors = []
                commit('addJournal', result)   
                await dispatch('loadJournalData', result.issn)
            }
        },
        async loadJournalData({ commit, dispatch }, issn) {
            commit('loading', true)
            let doc = fb.journalsCollection.doc(issn)

            let minDoc = await doc.collection('issues').orderBy('year', 'asc').orderBy('volume', 'asc').limit(1).get()
            let maxDoc = await doc.collection('issues').orderBy('year', 'desc').orderBy('volume', 'desc').limit(1).get()
            
            if(minDoc.docs.length) {
                minDoc = await minDoc.docs[0].data()
                maxDoc = await maxDoc.docs[0].data()
                commit('setJournalData', {
                    issn, 
                    data: { 
                        minYear: minDoc.year, 
                        maxYear: maxDoc.year,
                        minVolume: minDoc.volume,
                        maxVolume: maxDoc.volume
                    }
                })
            }

            await dispatch('loadJournalIssues', { issn })
            //await dispatch('loadJournalAuthors', { issn })
            commit('loading', false)
        },
        async loadJournalAuthors({ commit, state, dispatch }, { issn, firstLetter }) {
            commit('flushJournalData', {issn, items: ['authors']})
            let journal = state.list.find(journal => journal.issn == issn)
            let doc = fb.journalsCollection.doc(issn)
            let query = doc.collection('authors')
            if(firstLetter) {
                query = query.where('firstLetter', '==', firstLetter)
            }
            journal.authorsQuery = query.orderBy(fb.admin.firestore.FieldPath.documentId(), "asc")
            journal.authorsSnapshot = null
            await dispatch('loadMoreJournalAuthors', issn)
        },
        async doSearchAuthors({ state, commit }, payload) {
            commit('loading', true)
            let journal = state.list.find( ({issn}) => payload.issn == issn)
            let response = await fb.functions.searchAuthors(payload)
            commit('flushJournalData', {issn: payload.issn, items: ['authors']})
            if (!response || !response.data || !response.data.result) throw ('No data')
            const authors = response.data.result.map(doc => Object.assign(doc._source, {highlight: doc.highlight}))
            authors.forEach(doc => doc.institutions = doc.institutions?.elements || [])
            commit('addJournalAuthors', {issn: payload.issn, authors})
            journal.authorsSearchHasMore = false
            commit('loading', false)
        },
        async loadJournalIssues({commit, state, dispatch}, {issn, query={}}) {
            console.log(123333)
            commit('flushJournalData', {issn, items: ['issues']})
            let journal = state.list.find(journal => journal.issn == issn)
            let doc = fb.journalsCollection.doc(issn)

            journal.issuesQuery = doc.collection('issues').where("issn", "==", issn)
            for(let key in query) {
                if(!query[key]) continue
                journal.issuesQuery = journal.issuesQuery.where(key, '==', query[key])
            }
            let orders = {
                year: 'desc',
                volume: 'desc',
                issue: 'desc'
            }
            for(let key in orders) {
                if(query[key]) continue
                journal.issuesQuery = journal.issuesQuery.orderBy(key, orders[key])
            }
            journal.issuesSnapshot = null
            await dispatch('loadMoreJournalIssues', issn)
        },
        async loadMoreJournalIssues({ state, commit }, issn) {
            commit('loading', true)
            const MAX_RESULTS = 12
            let journal = state.list.find(journal => journal.issn == issn)
            console.log(111111, journal.issuesSnapshot)
            if(journal.issuesSnapshot) {
                journal.issuesSnapshot = await journal.issuesQuery.startAfter(journal.issuesSnapshot.docs.pop()).limit(MAX_RESULTS).get()
            } else {
                journal.issuesSnapshot = await journal.issuesQuery.limit(MAX_RESULTS).get()
            }

            journal.issuesSearchHasMore = journal.issuesSnapshot.size == MAX_RESULTS
            
            let issues = []
            for (let issue of journal.issuesSnapshot.docs) {
                issues.push(await getData(issue))
            }

            issues.forEach( async issue => {
                let cover = issue.cover || `issues/covers/${issue.issn}_${issue.year}_${issue.volume}_${issue.issue}_cover.jpg`
                let toc = issue.toc || `issues/toc/${issue.issn}_${issue.year}_${issue.volume}_${issue.issue}_toc.pdf`
                try {
                    Vue.set(issue, 'cover', await fb.storageRef.child(cover).getDownloadURL())
                    Vue.set(issue, 'toc', await fb.storageRef.child(toc).getDownloadURL())
                } catch {
                    
                }
            })

            commit('addJournalIssues', { issn, issues })
            commit('loading', false)
        },
        async loadMoreJournalAuthors({ state, commit }, issn) {
            commit('loading', true)
            const MAX_RESULTS = 10
            let journal = state.list.find(journal => journal.issn == issn)
            if(journal.authorsSnapshot) {
                journal.authorsSnapshot = await journal.authorsQuery.startAfter(journal.authorsSnapshot.docs.pop()).limit(MAX_RESULTS).get()
            } else {
                journal.authorsSnapshot = await journal.authorsQuery.limit(MAX_RESULTS).get()
            }
            journal.authorsSearchHasMore = journal.authorsSnapshot.size == MAX_RESULTS
            
            let authors = []
            for (let author of journal.authorsSnapshot.docs) {
                authors.push(await getData(author))
            }
            commit('addJournalAuthors', { issn, authors })
            commit('loading', false)
        }
    },

    mutations: {
        flushJournals(state) {
            state.list = []
        },
        addJournal(state, journal) {
            state.list.push(journal)
        },
        flushJournalData(state, {issn, items=['issues', 'authors']}) {
            let journal = state.list.find(journal => journal.issn == issn)
            if(journal) {
                for(let key of items) {
                    journal[key] = []
                }
            }
        },
        addJournalIssues(state, payload) {
            let journal = state.list.find(journal => journal.issn == payload.issn)
            if(journal) {
                journal.issues = journal.issues.concat(payload.issues)//.sort(s => s.id).reverse()
            }
        },
        addJournalAuthors(state, payload) {
            let journal = state.list.find(journal => journal.issn == payload.issn)
            if(journal) {
                journal.authors = journal.authors.concat(payload.authors)//.sort(s => s.id).reverse()
            }
        },

        setJournalData(state, payload) {
            let journal = state.list.find(journal => journal.issn == payload.issn)
            for(let item in payload.data) {
                Vue.set(journal, item, payload.data[item])
            }
        },
        loading(state, value) {
            state.loading = value
        }
    },

    getters: {
        journalsList(state) {
            return state.list
        },
        loadingJournalData(state) {
            return state.loading
        }
    }
}