<template>
    <div>
        <div class="card-header"><h5>{{translation.issues}}</h5></div>
        <div v-for="group in groupedIssues" class="row mt-3 mx-3 row-cols-1" :key="`year-label-${group.year}`">
            <div class="col px-0 pb-2 card">
                <div class="card-header"><h6>{{translation.year}}: {{group.year}}</h6></div>
                <div class="row mx-0 row-cols-3" :key="`issues-label-${group.year}`">
                    <div class="col" v-for="issue, idx in group.issues" :key="`list-${issue.id}-${idx}`">
                        <div class="card mt-2 pl-2 pr-2 pt-1 list-item">
                            <router-link :to="`/journal/${journal.issn}/issue/${issue.id}/`" class="text-center">
                                <img :src="issue.cover || `/images/cover-${journal.issn}.jpg`" class="card-img">
                                <span class="mr-1">{{translation.volume}}: {{issue.volume}},</span>
                                <span class="mr-1">{{translation.issue}}: {{issue.issue}}</span>
                            </router-link>
                            <small class="text-muted text-center mb-1">{{translation.year}}: {{issue.year}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div ref="loadMoreWrapper" class="row justify-content-center mt-2 mb-2" v-if="!limit">
            <div class="col col-md-3">
                <button  class="btn btn-info" @click="$store.dispatch('loadMoreJournalIssues', journal.issn)" v-if="journal.issuesSearchHasMore">{{translation.loadMore}}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['journal', 'doc', 'limit'],
    computed: {
        groupedIssues() {
            const list = this.journal.issues.sort((a,b) => b.year - a.year ).slice(0, this.limit)
            let result = _.groupBy(list, !this.limit ? 'year' : () => 'all')

            return Object.keys(result).map(year => ({year, issues: result[year]}) ).sort((a,b) => b.year - a.year )
        }
    },
    mounted() {
        setTimeout(() => {
            this.io = new IntersectionObserver(entries => {
                if(this.journal.issuesSearchHasMore)
                    this.$store.dispatch('loadMoreJournalIssues', this.journal.issn)
                }, {
                    threshold: [0]
                });
            if(this.$refs.loadMoreWrapper) {
                this.io.observe(this.$refs.loadMoreWrapper);
            }
        }, 1000)
    },
    unmounted() {
        this.io.disconnect()
    }
}
</script>

<style lang="less" scoped>
.card.list-item {
    width: 100%;
    img {
        width: 90%;
    }
}
</style>